import {
    GET_USER_FOLLOWINGS, GET_USER_INFO, GET_USER_MATCHING
} from "../actions/types";
const initialState = {
    following: [],
    info: null,
    matching: [],
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_USER_FOLLOWINGS:
            return {
                ...state,
                following: payload.data[0].following,
            };
        case GET_USER_INFO:
            return {
                ...state,
                info: payload.data[0],
            };
        case GET_USER_MATCHING:
            return {
                ...state,
                matching: payload.data,
            }

        default:
            return state;
    }
}
