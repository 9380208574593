import React from "react";
import {Link} from "react-router-dom";
import "./Navbar.css"
import phone from '../assets/Phone Icon.svg'
import email from '../assets/Email Icon.svg'
import clock from '../assets/Clock Icon.svg'
import {BsFillArrowRightCircleFill} from "react-icons/bs";
import {FaFacebookF, FaLinkedinIn, FaTwitter} from "react-icons/fa";
import Logo from '../assets/logo-removebg-preview.png'
import {NavHashLink} from "react-router-hash-link";
import axios from "axios";
import {LOGIN_SUCCESS} from "../actions/types";
const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
        top: elementPosition,
        left: 0,
        behavior: "smooth",
    });
};


const onRemoveClick = (e) => {
    document.getElementById("offcanvasNavbar").classList.remove("show");
    if (document.querySelector(".offcanvas-backdrop") !== null) {
        document.querySelector(".offcanvas-backdrop").classList.remove("show");
        let canvas = document.querySelector(".offcanvas-backdrop");
        canvas.style.display = "none";
    }
    // console.log(document.getElementsByClassName('active')[1].classList.replace("active","tt"))
    // document.getElementsByClassName('active')[1]?.classList?.replace("active","tt")
};

class NavBar extends React.Component {

    state = {
        navbarScroll: false,
        data:{},
        isLoading:true
    }

    componentDidMount() {
        window.addEventListener('scroll', this.changeNavBarBackground)
        axios.get(process.env.REACT_APP_API_DAM_URL+"/header")
            .then((res)=>{
                let data = res.data
                this.setState({data})
        })
    }

    changeNavBarBackground = () => {
        if (window.scrollY >= 80) {
            this.setState({
                navbarScroll: true
            })
        } else {
            this.setState({
                navbarScroll: false
            })
        }
    }



    render() {
        let {data} = this.state

        return (
            <>
                <div className='header-icons'>

                    <div className='title-header-here'>
                        <div className={"mx-3"}>
                            <img src={phone}/>
                            <span>Phone: {data.phone}</span>
                        </div>
                        <div className={"mx-3"}>
                            <img src={email}/>
                            <span>Email: {data.email}</span>
                        </div>
                        <div className={"mx-3"}>
                            <img src={clock}/>
                            <span>Hours: {data.hours}</span>
                        </div>
                    </div>

                    <div className='icon-social-header'>
                        <div>
                            <a href={data.facebook} className="noLink"  target="_blank">
                                <FaFacebookF/>
                            </a>
                        </div>
                        <div>
                            <a href={data.linkedin} className="noLink"  target="_blank">
                            <FaLinkedinIn/>
                            </a>
                        </div>
                        <div>
                            <a href={data.twitter} className="noLink"  target="_blank">
                            <FaTwitter/>
                            </a>
                        </div>
                    </div>

                </div>

                <nav
                    className="navbar navbar-light navbar-expand-lg changebg">
                    <div className="container">
                        <Link to="/" className="navbar-brand">
                                <img src={data.logo} width="160px" height="80px"/>
                        </Link>
                        <button
                            style={{width: "unset"}}
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasNavbar"
                            aria-controls="offcanvasNavbar"
                        >
                            <span className="navbar-toggler-icon"/>
                        </button>
                        <div
                            className="offcanvas offcanvas-end"
                            data-bs-scroll="true"
                            tabIndex="-1"
                            id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel"
                        >
                            <div className="offcanvas-header">
                                <NavHashLink
                                    className="offcanvas-title"
                                    id="offcanvasNavbarLabel"
                                    to="/"
                                >
                                    <img src={data.logo} width="160px" height="80px"/>
                                </NavHashLink>
                                <button
                                    type="button"
                                    className="btn-close text-reset"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="offcanvas-body">


                                <ul className="navbar-nav  justify-content-evenly  flex-grow-1  my-nav-bar">
                                    <li className="nav-item">
                                        <NavHashLink
                                            exact
                                            to={"/"}
                                            className="nav-link"
                                        >
                                            Home
                                        </NavHashLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavHashLink
                                            scroll={(el) => scrollWithOffset(el, 0)}
                                            to="/about"
                                            className="nav-link"
                                        >
                                            About
                                        </NavHashLink>
                                    </li>

                                    <li className="">
                                        <NavHashLink
                                            to="/our-team"
                                            className="nav-link"
                                        >
                                            Our Team
                                        </NavHashLink>
                                    </li>

                                    <li className="nav-item">
                                        {/*<NavHashLink*/}
                                        {/*    to="/single-project"*/}
                                        {/*    className="nav-link"*/}
                                        {/*>*/}
                                        {/*    Projects*/}
                                        {/*</NavHashLink>*/}
                                        <NavHashLink
                                            onClick={onRemoveClick}
                                            scroll={(el) => scrollWithOffset(el, 0)}
                                            to="/#Projects"
                                            className="nav-link"
                                        >
                                            Projects
                                        </NavHashLink>
                                    </li>
                                    <NavHashLink
                                        to="/contact-us"
                                        className="nav-link"
                                    ><li className="nav-item">

                                            Contact Us

                                    </li></NavHashLink>

                                </ul>
                                <div className="nav-item">
                                    <NavHashLink
                                        to="/#contactUS"
                                        className="nav-link"
                                    >
                                        <button className='btn edit-join-btn'>Contact Us
                                            <BsFillArrowRightCircleFill/>
                                        </button>

                                    </NavHashLink>
                                </div>
                            </div>

                        </div>
                    </div>
                </nav>
            </>
        );
    }
}

export default NavBar;
