export const chatConstants = {
  CHANGE_ROOM: "CHANGE_ROOM",
  CLEAR_ROOM: "CLEAR_ROOM",
  INIT_MESSAGE_ARRAY: "INIT_MESSAGE_ARRAY",
  NEW_MESSAGE: "NEW_MESSAGE",
  READ_MESSAGES: "READ_MESSAGES",
  RECEIVE_READ_MESSAGES: "RECEIVE_READ_MESSAGES",
  CHANGE_ACTIVITY_STATUS: "CHANGE_ACTIVITY_STATUS",
  ADD_NEW_ROOM: "ADD_NEW_ROOM",
  INC_MESSAGE_COUNT: "INC_MESSAGE_COUNT",
  SEARCH_USERS: "SEARCH_USERS",

  OPEN_CALLING_MODAL: "OPEN_CALLING_MODAL",
  CLOSE_CALLING_MODAL: "CLOSE_CALLING_MODAL",

  NEW_ANSWER: "NEW_ANSWER",

  OPEN_ANSWERING_MODAL: "OPEN_ANSWERING_MODAL",
  CLOSE_ANSWERING_MODAL: "CLOSE_ANSWERING_MODAL",

  TYPING: "TYPING",
  STOPPED_TYPING: "STOPPED_TYPING",

  GET_ROOMS_REQUEST: "GET_ROOMS_REQUEST",
  GET_ROOMS_SUCCESS: "GET_ROOMS_SUCCESS",

  GET_MESSAGES_INITIAL_REQUEST: "GET_MESSAGES_INITIAL_REQUEST",
  GET_MESSAGES_REQUEST: "GET_MESSAGES_REQUEST",
  GET_MESSAGES_SUCCESS: "GET_MESSAGES_SUCCESS",

  SEND_MESSAGE_REQUEST: "SEND_MESSAGE_REQUEST",
  SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",

  NEW_IMAGE_MESSAGE_REQUEST: "NEW_IMAGE_MESSAGE_REQUEST",
  NEW_IMAGE_MESSAGE_SUCCESS: "NEW_IMAGE_MESSAGE_SUCCESS"
};
