export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const GET_USER_FOLLOWINGS = "GET_USER_FOLLOWINGS";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_MATCHING = "GET_USER_MATCHING";
