import React from 'react'
import img from './editor-1s-47px.gif'
const Loader = () => {
    return (
        <>
            <div className="loader_wrapper">
                <span className="spinner-border spinner-border-sm" style={{borderColor:"orange"}}></span>
            </div>
        </>
    )
}

export default Loader
