import React from "react";
import {Redirect, Route, Router} from "react-router-dom";
import {history} from "./helpers/history";
import pMinDelay from "p-min-delay";
import loadable from "./components/loader/loadable";
import Loading from "./components/loader";
import NavBar from "./components/Navbar";

const ScrollToTop = loadable(
    () => pMinDelay(import("./components/ScrollToTop"), 250),
    {
        fallback: <Loading/>,
    }
);

const HeaderPage = loadable(
    () => pMinDelay(import("./components/Header/Header"), 250),
    {
        fallback: <Loading/>,
    }
);
const Boundaries = loadable(
    () => pMinDelay(import("./components/Boundaries/Boundaries"), 250),
    {
        fallback: <Loading/>,
    }
);
const InProgressProjects = loadable(
    () => pMinDelay(import("./components/In-Progress-Projects/InProgressProjects"), 250),
    {
        fallback: <Loading/>,
    }
);
const CompletedProjects = loadable(
    () => pMinDelay(import("./components/Completed Projects/CompletedProjects"), 250),
    {
        fallback: <Loading/>,
    }
);
const HomePage = loadable(
    () => pMinDelay(import("./components/Pages/HomePage/Home"), 250),
    {
        fallback: <Loading/>,
    }
);
const OurTeam = loadable(
    () => pMinDelay(import("./components/Pages/OurTeam/OurTeam"), 250),
    {
        fallback: <Loading/>,
    }
);
const ContactUs = loadable(
    () => pMinDelay(import("./components/Pages/ContactUs/ContactUs"), 250),
    {
        fallback: <Loading/>,
    }
);
const About = loadable(
    () => pMinDelay(import("./components/Pages/AboutUs/AboutUs"), 250),
    {
        fallback: <Loading/>,
    }
);
const SingleProject = loadable(
    () => pMinDelay(import("./components/Pages/SingleProject/SingleProject"), 250),
    {
        fallback: <Loading/>,
    }
);
const DownloadApp = loadable(
    () => pMinDelay(import("./components/Downloads/Download"), 250),
    {
        fallback: <Loading/>,
    }
);
const Partners = loadable(
    () => pMinDelay(import("./components/Partners/Partners"), 250),
    {
        fallback: <Loading/>,
    }
);
const Footer = loadable(
    () => pMinDelay(import("./components/Footer/Footer"), 250),
    {
        fallback: <Loading/>,
    }
);

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        return (
            <Router history={history}>
                <NavBar/>
                <ScrollToTop/>
                <Route exact path="/">
                    <HomePage/>
                </Route>
                <Route path="/about"><About/></Route>
                <Route path='/contact-us'><ContactUs/></Route>
                <Route path='/single-project/:id'><SingleProject/></Route>
                <Route path='/our-team'><OurTeam/></Route>
                <Footer/>
            </Router>

        );
    }

}


export default App;
