export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  GETUSER_REQUEST: "USER_GETUSER_REQUEST",
  GETUSER_SUCCESS: "USER_GETUSER_SUCCESS",
  GETUSER_FAILURE: "USER_GETUSER_FAILURE",

  DELETE_REQUEST: "USERS_DELETE_REQUEST",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",

  USER_UPDATE_REQUEST: "USER_UPDATE_REQUEST",
  USER_UPDATE_SUCCESS: "USER_UPDATE_SUCCESS",
  USER_UPDATE_FAILURE: "USER_UPDATE_FAILURE",

  USER_UPDATE_PROFILEPICTURE_SUCCESS: "USER_UPDATE_PROFILEPICTURE_SUCCESS",

  USER_LIKE_COMMENT: "USER_LIKE_COMMENT",
  USER_DISLIKE_COMMENT: "USER_DISLIKE_COMMENT",

  USER_DISLIKE_POST: "USER_DISLIKE_POST",
  USER_LIKE_POST: "USER_LIKE_POST",

  USER_LIKE_COMMENT_REPLY: "USER_LIKE_COMMENT_REPLY",
  USER_DISLIKE_COMMENT_REPLY: "USER_DISLIKE_COMMENT_REPLY",

  GET_USERPROFILE_DATA_REQUEST: "GET_USERPROFILE_DATA_REQUEST",
  GET_USERPROFILE_DATA: "GET_USERPROFILE_DATA",
  GET_USERPROFILE_DATA_FAILURE: "GET_USERPROFILE_DATA_FAILURE",

  GET_USER_PROFILE_FOLLOWINGS: "GET_USER_PROFILE_FOLLOWINGS",
  GET_USER_PROFILE_FOLLOWERS: "GET_USER_PROFILE_FOLLOWERS",

  GET_USER_FOLLOWINGS: "GET_USER_FOLLOWINGS",
  GET_USER_FOLLOWERS: "GET_USER_FOLLOWERS",

  FOLLOW_USER: "FOLLOW_USER",
  UNFOLLOW_USER: "UNFOLLOW_USER",

  GET_POSTS: "GET_POSTS",
  GET_USER_PROFILE_POSTS: "GET_USER_PROFILE_POSTS",

  PASSWORD_RESET_REQUEST: "PASSWORD_RESET_REQUEST",
  PASSWORD_RESET_RESPONSE: "PASSWORD_RESET_RESPONSE",

  GET_NEW_USERS_SUCCESS: "GET_NEW_USERS_SUCCESS",
  GET_NEW_USERS_REQUEST: "GET_NEW_USERS_REQUEST",
  NEW_USER: "NEW_USER",
};
