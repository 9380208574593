import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
// import { socket } from "./socketReducer";
import { chat } from "./chatReducer";
import { notification } from "./notificationReducer";
import user from "./user";

export default combineReducers({
  auth,
  message,
  // socket,
  chat,
  notification,
  user,
});
